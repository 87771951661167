<template lang="pug">
.start-session-countdown
  .start-session-icon
    Icon.fitted(value="window-pointer")
  .start-session-content
    .start-session-header {{ header }}
    .start-session-body {{ body }}
    .start-session-action-panel
      .start-session-timer-counter
        .start-session-counter {{ waitingCnt }} waiting
        .start-session-timer 
          Icon.fitted(value="clock")
          | {{ timerValue }}
      #admit-learners-button(
        data-name="admit-learners-button",
        :class="admitButtonClass",
        @mousedown="admitting = !admitting"
      ) {{ buttonText }}

      Confirminator(
        v-if="admitting",
        position="below",
        elementId="admit-learners-button",
        buttonText="Admit Learners",
        bodyText="Are you Sure?",
        :darkMode="true",
        @cancelled="admitting = false",
        @confirmed="onAdmitLearners"
      )
</template>
<script lang="ts">
import config from "@/config";
import { http } from "@/http";
import { RestartClassroom, StartClassroom, UserModel } from "@/types";
import { EventContentModel } from "@cruciallearning/puddle";
import { Confirminator, Icon } from "@cruciallearning/puddle/components";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

@Component({
  components: {
    Icon,
    Confirminator,
  },
  computed: {
    ...mapState(["isLeavingSession", "isClassroomStarted"]),
    ...mapState("EventModule", ["event"]),
    ...mapGetters("EventModule", [
      "effectiveSessionNumber",
      "currentSessionStart",
      "sessionNumber",
      "restartSessionNumber",
      "isRestarting",
    ]),
    ...mapGetters("UsersModule", ["getLobby"]),
  },
  methods: {
    ...mapActions(["startClassroom", "restartClassroom"]),
  },
})
export default class StartSessionCountdown extends Vue {
  private readonly getLobby!: UserModel[];
  private readonly isClassroomStarted!: boolean;
  private readonly isRestarting!: boolean;
  private readonly event!: EventContentModel;
  private readonly sessionNumber!: number;

  private readonly startClassroom!: StartClassroom;
  private readonly restartClassroom!: RestartClassroom;

  private admitting = false;
  private remainingSeconds = -1;
  private timeout?: NodeJS.Timeout;

  private readonly currentSessionStart!: Date;

  mounted(): void {
    this.remainingSeconds = Math.floor((this.currentSessionStart.getTime() - new Date().getTime()) / 1000);
    if (this.remainingSeconds > 0) {
      this.timeout = setInterval(() => {
        if (this.remainingSeconds > 0) {
          this.remainingSeconds--;
        } else {
          clearInterval(this.timeout);
        }
      }, 1000);
    }
  }

  get timerValue(): string {
    if (this.isReady) {
      return "00:00";
    }
    const minutes = Math.floor(this.remainingSeconds / 60);
    const seconds = this.remainingSeconds % 60;
    const padM = String(minutes).padStart(2, "0");
    const padS = String(seconds).padStart(2, "0");
    return `${padM}:${padS}`;
  }

  get isReady(): boolean {
    return this.remainingSeconds <= 0;
  }

  get header(): string {
    return this.isReady ? "Your session is scheduled to begin now!" : "Your session is scheduled to begin soon!";
  }

  get body(): string {
    return this.isReady
      ? "Admit the learners waiting in the lobby to begin."
      : "Take a moment to review the course, or you may admit learners from the lobby anytime.";
  }

  get waitingCnt(): number {
    return this.getLobby.length;
  }

  get admitButtonClass(): string {
    if (this.admitting) return "disabled-admit";
    return "";
  }

  get buttonText(): string {
    return this.admitting ? "Cancel" : "Admit Learners Now";
  }

  onAdmitLearners(): void {
    if (!this.isClassroomStarted) {
      this.$api.auditron.add(
        config.backendUrl,
        {
          action: "SESSION_START",
          targetObjectId: this.event.id,
          targetCollection: "learningEvent",
          salesforceId: this.event.salesForceId,
          tags: [
            "virtual",
            "session",
            "start",
            this.event.referenceNumber,
            `${this.$auth.authUser.given_name} ${this.$auth.authUser.family_name}`,
          ],
          changes: [
            {
              scope: this.isRestarting ? "events.sessions.restart" : "events.sessions.start",
              newValue: this.sessionNumber.toString(),
            },
          ],
        },
        http
      );

      if (this.isRestarting) {
        this.restartClassroom(this.event.id);
      } else {
        this.startClassroom();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.start-session-countdown {
  width: 580px;
  height: fit-content;
  border-radius: 3px;
  background-color: white;
  border-left: var(--error-50) solid 4px;
  display: flex;
  padding: 8px 16px;
  position: absolute;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  cursor: default;
}
.start-session-header {
  color: var(--gray-80);
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.start-session-icon {
  height: fit-content;
}
.start-session-content {
  margin-left: 8px;
  width: 100%;
  > div {
    margin: 8px 0;
  }
}
.start-session-action-panel {
  padding-top: 16px;
  display: flex;
}
.start-session-timer-counter {
  display: inline-flex;
  border-radius: 8px;
}
.start-session-counter {
  background-color: white;
  border: 1px solid #40414e;
  border-right: none;
  color: #40414e;
  padding: 8px;
  width: fit-content;
  margin: auto;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: 700;
}
.start-session-timer {
  background-color: #40414e;
  color: white;
  width: fit-content;
  padding: 8px;
  margin: auto;
  display: flex;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 95px;
  span {
    margin-right: 6px;
  }
}
#admit-learners-button {
  display: flex;
  height: 36px;
  width: 187px;
  padding: 8px 16px 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: auto;
  margin-right: 0;
  background: var(--info-50);
  color: white;
  font-style: normal;
  text-transform: capitalize;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: var(--info-20);
  }
}
.start-session-icon ::v-deep svg {
  width: 32px;
  height: 32px;
  path {
    fill: white;
    stroke: var(--error-50);
  }
}
.start-session-timer ::v-deep svg {
  width: 18px;
  height: 18px;
  path {
    fill: white;
  }
}
.disabled-admit {
  z-index: 201;
  background-color: var(--gray-30) !important;
  color: black !important;
}
</style>
