<template lang="pug">
.base-layout-container
  LockedHeader
  StartSessionOverlay(v-if="firstVisitPopup")
  component.content-component(:is="asyncComponent", :content="getActiveContent")
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Component as VueComponent } from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import LockedHeader from "./LockedHeader.vue";
import { Frame } from "@/types/course";
import StartSessionOverlay from "../Overlay/StartSessionOverlay.vue";

@Component({
  components: { LockedHeader, StartSessionOverlay },
  computed: {
    ...mapGetters("CourseModule", ["getActiveContent"]),
    ...mapState(["firstVisitPopup"]),
  },
  methods: {
    ...mapActions("SidebarModule", ["openSideView"]),
  },
})
export default class BaseLayout extends Vue {
  private getActiveContent!: Partial<Frame[]>;

  get asyncComponent(): () => Promise<VueComponent> {
    let layout = FrameType.CONTENT;
    if (this.getActiveContent && this.getActiveContent.length > 1) {
      if (this.getActiveContent[1]?.video) layout = FrameType.VIDEO;
      if (this.getActiveContent[1]?.poll) layout = FrameType.POLL;
    }
    return async (): Promise<VueComponent> => import(`@/components/Layout/Layouts/${layout}Layout`);
  }
}
export enum FrameType {
  CONTENT = "Content",
  POLL = "Poll",
  VIDEO = "Video",
}
</script>
<style lang="scss" scoped>
.button:hover {
  box-shadow: none;
  border-color: none;
}
.base-layout-container {
  overflow: hidden;
  position: relative;
}
</style>
