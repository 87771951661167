<template lang="pug">
.locked-header
  .practice-banner(v-if="showPracticeBanner")
    Icon.fitted.practice-banner-icon(value="banner-alert")
    p Practice Mode | Not to be used in training
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { EventContentModel } from "@cruciallearning/puddle";
import { mapActions, mapState } from "vuex";
import { Icon } from "@cruciallearning/puddle/components";

@Component({
  components: {
    Icon,
  },
  computed: {
    ...mapState("EventModule", ["event"]),
    ...mapState(["firstVisitPopup"]),
  },
  methods: {
    ...mapActions(["startClassroom", "restartClassroom"]),
  },
})
export default class LockedHeader extends Vue {
  private readonly event!: EventContentModel;
  private readonly firstVisitPopup!: boolean;

  get showPracticeBanner(): boolean {
    return this.event.practice && !this.firstVisitPopup;
  }
}
</script>
<style lang="scss" scoped>
.locked-header {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 13;
  z-index: 501;
  height: fit-content;
  position: absolute;
  width: 100%;
}
.practice-banner {
  background-color: #ff993b;
  z-index: 50;
  text-align: left;
  width: 100%;
  height: 64px;
  border-bottom: 1px #d6d6d6 solid;
  display: flex;
}
.practice-banner p {
  margin: auto 0 auto 16px;
  color: black;
  text-align: left;
  font-family: Flama;
  font-size: 20px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
::v-deep svg {
  height: 24px;
  width: 24px;
  path {
    fill: black;
  }
}
.practice-banner-icon {
  margin: auto 0 auto 16px;
}
</style>
