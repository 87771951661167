import { RootState, UserModel } from "@/types";
import { sockets } from "@/services/socket_api";
import AnnotationsModule from "@/store/modules/Annotations";
import BreakoutsModule from "@/store/modules/Breakouts";
import ChatModule from "@/store/modules/Chat";
import PollModule from "./modules/Poll";
import SidebarModule from "@/store/modules/Sidebar";
import socket from "@/services/socket";
import GridModule from "./modules/Grid";
import LoaderModule from "@/store/modules/Loader";
import UsersModule from "@/store/modules/Users";
import VideoModule from "@/store/modules/Video";
import MediaModule from "@/store/modules/Media";
import VonageModule from "@/store/modules/Vonage";
import SystemCheckModule from "@/store/modules/SystemCheck";
import Vue from "vue";
import Vuex from "vuex";
import api from "@/services/api";
import { getInstance } from "@cruciallearning/puddle/auth";
import CourseModule from "./modules/Course";
import EventModule from "./modules/Event";
import IconModule from "./modules/Icon";
import TimerModule from "./modules/Timer";
import ErrorModule from "./modules/Error";
import BrowserModule from "./modules/Browser";

Vue.use(Vuex);

/**
 * --< Default Vue store >--
 * The main file controls any global app settings that may
 * need to change from deep locations or non accessible
 * siblings or grandparents.
 */
export default new Vuex.Store({
  modules: {
    AnnotationsModule,
    BreakoutsModule,
    BrowserModule,
    ChatModule,
    CourseModule,
    ErrorModule,
    EventModule,
    GridModule,
    IconModule,
    LoaderModule,
    MediaModule,
    PollModule,
    SidebarModule,
    SystemCheckModule,
    TimerModule,
    UsersModule,
    VonageModule,
    VideoModule,
  },
  state(): RootState {
    return {
      captions: true,
      courseId: "blt3f457e94b65ad263",
      debugEnabled: false,
      isCheckingHardware: false,
      isClassroomConnected: false,
      isClassroomStarted: false,
      isClassroomWaiting: true,
      isConnected: false,
      isEndingSession: false,
      isInitialLoad: true,
      isLeavingSession: false,
      isMediaAllowed: true,
      isStaleSession: false,
      language: "en",
      selectedAudioId: "",
      selectedVideoId: "",
      selectedSpeakerId: "",
      streamSessionId: "",
      streamToken: "",
      breakoutVonageToken: "",
      breakoutSessionId: "",
      trainingEventId: "Session05112022",
      wasDisconnected: false,
      wasRemoved: false,
      activeSpeakerId: "",
      breakoutActiveSpeakerId: "",
      userRaisedHand: false,
      downloadingChat: false,
      learnerAttendance: false,
      firstVisitPopup: false,
    };
  },
  getters: {
    isUserReady(state: RootState, _getters, _rootState, rootGetters): boolean {
      const ready = state.isConnected && state.isClassroomConnected;
      if (getInstance().ADMIN) return ready;
      const self: UserModel | undefined = rootGetters["UsersModule/getSelf"];
      const waiting = self ? self.waiting : true;
      return ready && !waiting && state.isMediaAllowed;
    },
    isUserWaiting(state: RootState, _getters, _rootState, rootGetters): boolean {
      if (getInstance().ADMIN) return false;
      const self: UserModel | undefined = rootGetters["UsersModule/getSelf"];
      const waiting = self ? self.waiting : true;
      return state.isConnected && state.isClassroomConnected && waiting;
    },
    showInactiveMessage(state: RootState): boolean {
      if (!getInstance().ADMIN) return false;
      return state.isStaleSession;
    },
  },
  mutations: {
    setRootState(state: RootState, rootStateOptions: Partial<RootState>): void {
      Object.assign(state, rootStateOptions);
    },
    setCheckingHardware(state: RootState, isCheckingHardware: boolean): void {
      state.isCheckingHardware = isCheckingHardware;
    },
  },
  actions: {
    connect(): void {
      const auth = getInstance();
      if (auth.authUser.token) {
        socket.connect(auth.authUser.token);
      }
    },
    connectToClassroom({ commit, rootGetters }): void {
      const streamEnabled = rootGetters["EventModule/streamEnabled"];
      if (!streamEnabled) {
        sockets.classroom.connect();
      } else {
        OT.getUserMedia()
          .then(() => sockets.classroom.connect())
          .catch(() => commit("setRootState", { isMediaAllowed: false }));
      }
    },
    classroomStateChange({ commit }, classroomOptions: Partial<RootState>): void {
      commit("setRootState", { ...classroomOptions, isClassroomWaiting: false });
    },
    classroomError(_, error: string[]): void {
      console.log(error);
    },
    disconnect({ commit }, remove = false): void {
      if (remove) commit("setRootState", { wasRemoved: remove });
      socket.disconnect();
    },
    processInactivity({ commit }, inactivity: number): void {
      const inactivityWindow = 60 * 60 * 1000;
      if (inactivity >= inactivityWindow) {
        commit("setRootState", { isStaleSession: true });
      }
    },
    startClassroom({ commit }): void {
      commit("setRootState", { isClassroomWaiting: true });
      sockets.classroom.start();
    },
    restartClassroom({ commit }, eventId: string): void {
      commit("setRootState", { isClassroomWaiting: true });
      sockets.classroom.restart();
      api.event.reopen(eventId);
    },
    stopClassroom({ commit }): void {
      commit("setRootState", { isClassroomWaiting: true, isStaleSession: false });
      sockets.classroom.stop();
    },
    admitToClassroom(_, id: string): void {
      sockets.classroom.admit(id);
    },
    removeFromClassroom(_, id: string): void {
      sockets.classroom.leave(id);
    },
    removeAllFromClassroom(): void {
      sockets.classroom.removeAll();
    },
    extendSession(): void {
      sockets.general.send("", "session.continue");
    },
    update({ commit }, rootStateOptions: Partial<RootState>): void {
      commit("setRootState", rootStateOptions);
    },
  },
});
