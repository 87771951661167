/*
 * Adds some convenience methods to globalThis for easy access from the
 * browser's JS console.
 *
 * >~~> THESE SHOULD NOT USED IN SOURCE CODE <~~<
 *
 * This is intended to support dev/debugging activities in the browser.
 */
import { KeyUnknownModel } from "@/types";
import WebMediaUtil from "@/utils/WebMediaUtil";

declare global {
  interface Window {
    __CLXG__: KeyUnknownModel;
  }
}

class Globals {
  public static init(): void {
    // ensure the container is initialized
    window.__CLXG__ = window.__CLXG__ || {};
    // populate some handy utility types
    Globals.addGlobal("WebMediaUtil", WebMediaUtil);
  }

  /**
   * Adds a property to our global-scope container.
   */
  public static addGlobal(key: string, value: unknown): void {
    window.__CLXG__[key] = value;
  }
}

export default Globals;
